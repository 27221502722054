import React from "react";

function ReactIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="url(#gradient)"
            viewBox="0 0 16 16"
        >
            <path
                d="M4.85 2.372c-.251.145-.515.544-.546 1.416a.75.75 0 01-1.499-.053c.038-1.068.376-2.131 1.295-2.662.922-.532 2.015-.29 2.961.214a.75.75 0 01-.706 1.324c-.773-.413-1.253-.384-1.505-.24zm4.021 1.3a.75.75 0 011.056.1c.555.669 1.087 1.432 1.57 2.268 1.04 1.802 1.681 3.648 1.854 5.196.086.773.06 1.509-.12 2.14-.183.635-.537 1.21-1.131 1.553-.719.415-1.55.357-2.309.08-.762-.278-1.547-.803-2.295-1.486a.75.75 0 011.011-1.108c.66.602 1.279.996 1.797 1.184.522.19.85.145 1.046.031.164-.095.33-.288.438-.667.11-.384.145-.91.072-1.56-.145-1.299-.7-2.947-1.663-4.613A14.994 14.994 0 008.772 4.73a.75.75 0 01.1-1.056z"
            ></path>
            <path
                d="M14.5 8.206c0-.29-.214-.719-.953-1.18a.75.75 0 01.795-1.273C15.248 6.32 16 7.144 16 8.206c0 1.064-.756 1.89-1.666 2.457a.75.75 0 01-.793-1.273c.744-.464.959-.894.959-1.184zm-3.137 2.832a.75.75 0 01-.614.865c-.857.145-1.784.224-2.749.224-2.08 0-4-.367-5.427-.992-.713-.311-1.336-.702-1.792-1.174C.32 9.486 0 8.891 0 8.206c0-.83.466-1.52 1.085-2.04.622-.521 1.47-.938 2.435-1.244a.75.75 0 01.454 1.43c-.85.27-1.502.609-1.924.964-.426.356-.55.663-.55.89 0 .19.085.43.359.713.278.287.716.58 1.315.842 1.197.524 2.902.866 4.826.866.885 0 1.727-.072 2.498-.203a.75.75 0 01.865.614z"
            ></path>
            <path
                d="M4.872 13.63c.25.145.729.174 1.499-.235a.75.75 0 11.704 1.325c-.944.501-2.034.74-2.953.21-.922-.533-1.26-1.6-1.295-2.672a.75.75 0 011.499-.05c.03.875.294 1.277.546 1.422zm-.884-4.133a.75.75 0 01-.442-.964c.302-.815.697-1.657 1.18-2.493 1.04-1.802 2.318-3.28 3.572-4.204.626-.461 1.276-.806 1.913-.965.641-.16 1.317-.141 1.91.202.72.415 1.084 1.163 1.224 1.96.141.799.078 1.741-.14 2.73a.75.75 0 11-1.464-.321c.191-.872.223-1.606.127-2.15-.096-.546-.3-.807-.496-.92-.164-.095-.415-.142-.797-.046-.388.097-.86.33-1.387.718-1.052.774-2.202 2.08-3.163 3.746-.443.766-.801 1.532-1.073 2.265a.75.75 0 01-.964.442z"
            ></path>
            <path
                d="M9.46 8.066a1.36 1.36 0 11-2.72 0 1.36 1.36 0 012.72 0z"
            ></path>
        </svg>
    );
}

export default ReactIcon;
